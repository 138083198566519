body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Poppins';
}

/* FOR DEBUG */
/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

/* Page too wide in mobile fix */
/* * {
  box-sizing: border-box
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerDiv {
  margin: auto;
  /* margin-left: 356px;
  margin-right: 356px; */
  margin-bottom: 16px;
  width: 1208px;
}

.headerTop {
  display: flex;
  flex-direction: row;
}

.logoDiv {
  margin-top: 50px;
  margin-left: -20px;
}

.logoDiv img {
  height: 62px;
  width: 250px;
}

.navActive {
  color:#001A3D;

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 12px;
    position: relative;
    bottom: 2px;
  }
}

.languageDiv {
  height: 23px;
  margin: 20px 0 auto auto;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #838C98;
}

.languageDiv > div:hover {
  color:#001A3D;
}

.languageDiv > div {
  cursor: pointer;
}

.headerBottom {
  display: flex;
  flex-direction: row;
  margin-top: 27px;
}

.headerButtonsDiv {
  display: flex;
  flex-direction: row;
  width: 920px;
  margin-top: 21px;
  font-weight: 600;
  color: #838C98;
}

.buttonDiv {
  height: 23px;
  margin-right: 30px;
  cursor: pointer;
}

.buttonDiv:hover {
  color:#001A3D;

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 12px;
    position: relative;
    bottom: 2px;
  }
}

.productListMobile .buttonDiv:hover {
  .arrow {
    margin-left: 6px;
  }
}

.headerSearchDiv, .mobileSearchDiv {
  background-color: #EDEDED;
  height: 45px;
  width: 380px;
  display: none !important;
}

.headerSearchDiv img, .mobileSearchDiv img {
  float: right;
  margin: 10px;
  width: 22px;
  height: 22px;
}

.mechaniniaiKomponentai {
  width: 1208px;
  height: 392px;
  background-color: #001A3D;
}

.mechaniniaiKomponentai div {
  color: white;
}

.komponentuDiv {
  width: 392px;
  height: 167px;
  padding: 113px 0 112px 102px;
  text-align: left;
  float: left;
}

.komponentai {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-wrap: wrap;
}

.paskirtis {
  margin-top: 20px;
  font-size: 20px;
  display: flex;
  font-weight: 600;
}

.paskirtis > div {
  width: 120px;
  border-left: 1px solid white;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 26px;
}

.paskirtis > div:first-child{
  border-left: none;
  padding-left: 0;
}

.paskirtis > div:nth-child(2){
  padding-right: 32px;
}

.komponentuImgDiv {
  width: 704px;
  height: 392px;
  float: left;
  padding-left: 10px;
}

.komponentuImgDiv > img {
  -webkit-mask-image: url(../public/assets/KomponentuMask.svg);
  mask-image: url(../public/assets/KomponentuMask.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat; 
}

.productCard {
  height: 392px;
  width: 392px;
  background-color: lightgray;
  text-align: left;
  position: relative;
  cursor: pointer;
}

.productCard > h3 {
  margin: 30px 0 0 40px;
  font-size: 30px;
  font-weight: 600;
  width: 200px;
  line-height: 38px;
}

.productCard > .categories {
  margin: 0 0 0 40px;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 240px;
}

.categories > div {
  font-weight: 500;
}

.productList {
  display: grid;
  margin: auto;
  margin-top: 16px;
  column-gap: 16px;
  row-gap: 16px;
  width: 1208px;
  grid-template-columns: auto auto auto;
}

.mainPageProducts {
  margin: auto;
  margin-top: 29px;
  width: 1208px;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 6px;
  position: relative;
  bottom: 2px;
}

nav .arrow {
  border: solid #838C98;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 12px;
  position: relative;
  bottom: 2px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

nav .arrow.up{
  top: 2px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.galimybes {
  grid-column: span 2;
  text-align: left;
  color: #001A3D;
}

.galimybes > h3 {
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 30px;
  width: 162px;
  font-weight: 600;
}

.galimybes > p {
  margin-left: 40px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.operacijos {
  width: 1208px;
  height: 948px;
  background-color: #001A3D;
  margin-top: 16px;
}

.operacijos > h3 {
  color: white;
  text-align: left;
  padding: 30px 0 0 38px;
  float: left;
  font-size: 28px;
  font-weight: 600;
}

.operacijos > img {
  margin-left: 0px;
}

.susisiekimas {
  width: 1208px;
  height: 500px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 100px;
}

.susisiekimas > h3 {
  color: #001A3D;
}

.footerDiv {
  /* width: 1920px; */
  width: 100%;
  height: 700px;
  background-color: #EDEDED;
  display: block;
}

.footerDiv > div {
  margin: auto;
  width: 1208px;
}

.cardImg {
  position: absolute;
}

.cardArrow {
  position: absolute;
  top: 328px;
  left: 312px;
}

.productPage {
  margin: auto;
  width: 1208px;
  display: block;
}

.productPage h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px; 
  width: 90px;
  margin-top: 0px;
}

.tech h5, .tech > div{
  float: left;
}

.gaminiai h5, .gaminiai > div{
  float: left;
}

.pvzTop h5, .pvzTop > .nuotraukos {
  float: left;
}

.pavadinimas {
  height: 100px;
  padding-bottom: 30px;
}

.pavadinimas, .gaminiai, .tech, .pvz {
  color: #001A3D;
}

.pavadinimas > h3 {
  float: left;
  font-size: 48px;
  font-weight: 600;
}

.pavadinimas > .karusele {
  float: right;
  display: block;
  padding-top: 60px;
}

.karusele > div {
  float: left;
  cursor: pointer;
  font-weight: 600;
}

.karusele > .borderDiv{
  height: 50px;
  border-right: 1px solid #001A3D;
  position: relative;
  bottom: 8px;
  margin-right: 20px;
}

.karusele img {
  height: 23px;
  position: relative;
  top: 5px;
}

.karusele .arrowLeftImg{
  margin-right: 25px;
}

.karusele .arrowRightImg{
  margin-left: 25px;
}

.karusele > div:first-child{
  padding-right: 20px;
}

.gaminiai, .tech {
  position: relative;
  width: 1208px;
  display: inline-block;
  bottom: 30px;
}

.tech {
  padding-top: 4px;
  margin-bottom: 10px;
}

.gaminiai {
  padding-top: 10px;
  border-bottom: 1px solid #001A3D;
  border-top: 1px solid #001A3D;
}

.gaminiai > div, .tech > div {
  float: left;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.gaminiai > div:nth-child(2) {
  left: 116px;
  width: 650px;
  padding-bottom: 10px;
}

.tech > div:nth-child(2) {
  left: 116px;
  width: 650px;
}

.tech > div:first-child{
  width: 90px;
}

.lentele {
  border-bottom: 1px solid #001A3D;
  display: inline-block;
  padding-bottom: 50px;
}

.lentele > * {
  float: left;
}

.pvz {
  border-bottom: 1px solid #001A3D;
  display: inline-block;
}

.pvz > div > div {
  float: left;
}

.pvz > .pvzTop > div:first-child {
  margin-right: 101px;
  width: 87px;
}

.gaminioLentele {
  margin-left: 78px;
}

.nuotraukos {
  display: inline-block;
  margin-top: 50px;
  margin-left: 98px;
}

.gaminioNuotrauka {
  float: left;
  height: 188px;
  width: 188px;
  margin-left: 16px;
  cursor: pointer;
}

.nuotraukosPlaceholder {
  cursor: default;
}

.ikonos {
  display: block;
  margin-left: 205px;
}

.excl {
  position: relative;
  top: 52px;
}

.ikonos .arrowLeftImg {
  position: relative;
  left: 890px;
  top: 30px;
  cursor: pointer;
}

.ikonos .arrowRightImg {
  position: relative;
  left: 950px;
  top: 30px;
  cursor: pointer;
}

.papildomaInfo {
  margin-top: 60px;
  display: inline-block;
  margin-bottom: 70px;
}

.papildomaInfo > .borderDiv {
  height: 100px;
  border-right: 1px solid #001A3D;
  position: relative;
  bottom: 15px;
}

.infoText {
  width: 448px;
  margin-left: 206px;
  padding-right: 40px;
  line-height: 20px;
  font-weight: 500;
  color: #001A3D;
}

.download {
  width: 232px;
  height: 25px;
  background-color: #001A3D;
  margin-left: 50px;
  margin-top: 10px;
  display: inline-block;
  color: white;
  text-align: center;
  padding: 12px 10px 10px 10px;
  font-weight: 600;
  font-size: 14px;
  border: none;
  cursor: pointer;
  text-decoration-line: none;
}

.apieMus, .esParam {
  width: 1108px;
  margin: auto;
  margin-top: 30px;
  padding: 30px 0px 0px 100px;
  background-color: #EDEDED;
  color: #001A3D;
}



.esParam {
  padding-bottom: 70px;
  
}

.apieMusTop, .esParamTop{
  display: inline-block;
}

.apieMus h3, .esParam h3{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.textDiv {
  line-height: 20px;
  font-weight: 500;
}

.apieMusTop > *, .esParamTop > * {
  float: left;
}

.apieMusTop > div > div {
  width: 700px;
  margin-right: 300px;
}

.esParamTop > div > div {
  width: 900px;
  margin-right: 100px;
}

.apieMus > .apieMusTop img {
  margin-top: 25px;
}

.esParam > .esParamTop img {
  margin-top: 25px;
}

.apieMusBot {
  padding-bottom: 70px;
}

.apieMusBot > div {
  display: inline-block;
}

.apieMusBot > div > div {
  float: left;
  width: 494px;
}

.apieMusBot > div > div:first-child {
  margin-right: 50px;
}

.apieMusOp {
  margin: auto;
  margin-top: 16px;
}

.kolektyvas {
  margin: auto;
  margin-top: 66px;
  height: 500px;
  width: 1208px;
  color: #001A3D;
}

.kolektyvas > h3 {
  font-size: 36px;
  font-weight: 600;
}

.kolektyvas > div {
  display: inline-block;
  margin-left: 103px;
  margin-top: 60px;
  width: 1030px;
}

.kolektyvas > div:nth-child(2) {
  border-bottom: 1px solid #001A3D;
  padding-bottom: 33px;
}

.kolektyvas h5 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.asmuo {
  float: left;
  width: 500px;
}

.asmuo > div {
  float: left;
  position:relative;
  bottom: 38px;
}

.asmuo .role{
  line-height: 20px;
}

.asmuo > div > div {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

div > .asmuo:first-child{
  margin-right: 10px;
}

.asmuo > img {
  float: left;
  margin-right: 50px;
}

.role {
  color: #EC4600;
}

.formDiv > form {
  display: inline-block;
}

.formDiv > form > div {
  float: left;
}

.formDiv input {
  background-color: #EDEDED;
  border: none;
}

.formSuccessDiv {
  width: 1208px;
  height: 350px;
  background-color: #001A3D;
  color: white;
}

.formSuccessDiv h3 {
  padding-top: 100px;
  padding-left: 100px;
  font-weight: 500;
  font-size: 30px;
}

.formSuccessDiv .successMsg {
  margin-top: 50px;
  padding-left: 100px;
  width: 450px;
  font-size: 21px;
  font-weight: 500;
  line-height: 26px;
}

.formSuccessDiv button {
  width: 188px;
  height: 45px;
  display: block;
  color: #001A3D;
  background-color: #EDEDED;
  border: none;
  cursor: pointer;
  font-weight: 600;
  position:relative;
  left: 968px;
  bottom: 55px;
}

.formSuccessDiv .iksas {
  position: relative;
  left: 1125px;
  bottom: 223px;
}

.susisiekimas.sent {
  margin-bottom: 220px;
}

.inputDiv {
  width: 474px;
  height: 45px;
  padding: 0px 0px 0px 20px;
  font-weight: 500;
}

.textInputDiv {
  width: 672px;
  height: 121px;
  padding: 12px 0px 0px 20px;
  margin-top: 0px;
  margin-left: 16px;
  resize: none;
  background-color: #EDEDED;
  border: none;
  font-weight: 500;
}

.zinute .inputComment {
  margin-left: 16px;
}

.kontaktai > div {
  margin-bottom: 20px;
}

.inputComment {
  color: #838C98;
  font-size: 12px;
  margin-top: 5px;
  width: 200px;
  font-weight: 500;
}

.kontaktai, .zinute {
  float: left;
}

.kontaktai {
  width: 500px;
}

form button {
  width: 188px;
  height: 45px;
  display: block;
  margin-left: 520px;
  margin-top: 20px;
  color: white;
  background-color: #001A3D;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.kont {
  display: inline-block;
  margin-bottom: 70px;
  color: #001A3D;
}

.kont > div {
  float: left;
  font-size: 21px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.kont1 {
  width: 320px;
}

.kont2 {
  width: 169px;
  flex-direction: column;
  justify-content: center;
}

.kont > .kont2 {
  align-items: initial;
}

.kont1, .kont2 {
  border-right: 1px solid #001A3D;
  padding-right: 50px;
}

.kont2, .kont3 {
  padding-left: 50px;
}

.kont3 {
  width: 266px;
}

.susisiekimas > h3 {
  font-size: 36px;
  font-weight: 600;
}

.susisiekimasMobile > h3 {
  font-size: 36px;
  font-weight: 600;
  color: #001A3D;
}

.bigImage {
  width: 1004px;
  height: 600px;
  margin-left: 113px;
  margin-top: 25px;
}

.bigKarusele {
  margin-top: 16px;
}

.iksas {
  cursor: pointer;
  position: relative;
  right: 88px;
  width: 30px;
  height: 30px;
  margin-top: 25px;
}

.bigImageIkonos {
  margin-left: 135px;
}

.bigImageArrowDiv {
  height: 0;
  position: relative;
  bottom: 315px;
}

.bigImageArrowDiv > .arrowLeftImg {
  position: relative;
  left: 10px;
  cursor: pointer;
}

.bigImageArrowDiv > .arrowRightImg {
  position: relative;
  left: 1187px;
  cursor: pointer;
}

.dropdownMenu {
  position: absolute;
  background-color: #EDEDED;
  width: 290px;
  height: 392px;
  margin-top: 29px;
  z-index: 1001;
}

.dropdownMobile {
  display: none;
}

.dropdownMenu ul {
  padding-top: 10px;
}

.dropdownItem {
  list-style-type: none;
  line-height: 34px;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}

.dropdownLink {
  color: #838C98
}

.dropdownLink:hover {
  color: #001A3D
}

.dropdownLink.active {
  color: #001A3D
}

.footerDiv .imonesInfo, .footerDiv .location {
  float: left;
}

.imonesInfo {
  height: 600px;
  /* margin: 100px 0 0 356px; */
  margin-top: 100px;
  margin-bottom: 0;
  position: absolute;
  color: #001A3D;
}

.imonesInfo h3 {
  font-weight: 600;
}

.imonesInfo div {
  font-size: 14px;
}

.imonesInfo .border {
  width: 86px;
  border-bottom: 1px solid #001A3D;
  margin-top: 25px;
  margin-bottom: 25px;
}

.footerKontaktai {
  font-weight: 600;
}

.locationImage {
  width: 1358px;
  height: 700px;
  margin-left: 140px;
  -webkit-mask-image: linear-gradient(270deg, black 70%, transparent);
  mask-image: linear-gradient (270deg, black 70%, transparent);
}

.location {
  width: 1358px;
  height: 700px;
}

.kontaktai .validationError{
  float: left;
  position: absolute;
  top: 50px;
  left: 120px;
}

.zinute .validationError{
  float: left;
  position: absolute;
  top: 137px;
  left: 120px;
}

.validationError > p {
  font-size: 12px;
  color: #EC4600;
  margin: 0;
}

.activeError {
  color: #EC4600;
}

.kontaktai > div, .zinute > div {
  position: relative;
}

.footerTextDiv {
  font-weight: 500;
}

.mobileOperacijosH {
  display: none;
}

.susisiekimasMobile, .katalogasMobile, .mobileSearchDiv, .headerDivMobile, .galimybes .buttonDiv {
  display: none;
}

.headerDivMobile img{
  cursor: pointer;
}

.productListMobile {
  display: none;
}

.lenteleIkonaDiv {
  min-width: 126px;
}

.karusele.karuseleMobile {
  display: none;
}

.locationImageMap {
  height: 700px;
  width: 1358px;
  margin-left: 180px;
  -webkit-mask-image: linear-gradient(270deg, black 70%, transparent);
  mask-image: linear-gradient (270deg, black 70%, transparent);
}

.greyGradient{
  position: absolute;
  width: 60%;
  height: 700px;
  background: linear-gradient(90deg, #f5f5f5, rgba(255,0,0,0) 35%);
  z-index: 1000;
  pointer-events: none;
}

@media only screen and (max-width: 1900px) {
  .locationImageMap {
    height: 700px;
    width: 1058px;
    margin-left: 150px;
    -webkit-mask-image: linear-gradient(270deg, black 70%, transparent);
    mask-image: linear-gradient (270deg, black 70%, transparent);
  }
}

@media only screen and (max-width: 1300px) {
  .productList {
    width: 1044px;
    grid-template-columns: auto auto;
  }

  .pvz {
    width: 1044px;
  }

  .pvzTop {
    position: relative;
    left: 107px;
  }

  .papildomaInfo {
    position: relative;
    left: 107px;
    margin-bottom: 30px;
    margin-top: 100px;
  }

  .papildomaInfo .download {
    width: 273px;
  }

  .productCard {
    width: 514px;
    height: 300px;
  }

  .lenteleIkonaDiv > img {
    display: none;
  }

  .productCard > .categories {
    top: 150px;
  }

  .cardImg {
    margin-left: 122px;
  }

  .cardArrow {
    top: 246px;
    left: 434px;
  }

  .mechaniniaiKomponentai {
    width: 1044px;
  }

  .komponentuImgDiv {
    margin-left: -164px;
  }

  .mainPageProducts {
    width: 1044px;
  }

  .headerDiv {
    width: 1044px;
  }

  .galimybes > h3, .galimybes > p {
    margin-left: 0;
  }

  .galimybes > p {
    width: 740px;
  }

  .operacijos{
    width: 1044px;
  }

  .operacijos > h3 {
    display: none;
  }

  .mobileOperacijosH {
    color:#001A3D;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    display: inherit;
    margin: 25px 0 50px 0;
  }

  .susisiekimas {
    width: 1044px;
  }

  .textInputDiv {
    width: 514px;
    margin-left: 10px;
    height: 122px;
  }

  .zinute {
    width: 500px;
  }

  form button {
    margin-left: 356px;
    margin-top: 19px;
  }

  .footerDiv {
    height: 575px;
  }

  .locationImage {
    height: 574px;
    width: 960px;
    margin-left: 200px;
  }

  .locationImageMap {
    height: 574px;
    width: 900px;
    margin-left: 150px;
  }

  .location {
    height: 574px;
  }

  .imonesInfo {
    height: 480px;
  }

  .footerDiv > div {
    width: 1044px;
  }

  .apieMus, .esParam {
    width: 944px;
  }

  .apieMusTop > div > div {
    margin-right: 90px;
    width: 750px;
  }

  .apieMus > .apieMusTop img {
    margin-top: 40px;
  }

  .apieMusBot > div > div {
    width: 750px;
  }

  .kolektyvas {
    width: 1044px;
  }

  .kolektyvas > div {
    margin-left: 0;
  }

  .esParamTop > div > div {
    width: 750px;
  }

  .esParam img {
    margin-top: 42px;
  }

  .productPage {
    width: 1044px;
  }

  .gaminiai, .tech {
    width: 1044px;
  }

  .lentele > img:first-child {
    display: none;
  }

  .lentele {
    width: 1044px;
    border-bottom: none;
  }

  .gaminioLentele {
    width: 1044px;
    margin-left: 0;
  }

  .nuotrauka5 {
    display: none;
  }

  .ikonos .arrowLeftImg{
    top: 270px;
    left: 0px;
  }

  .ikonos .arrowRightImg{
    top: 270px;
    left: 55px;
  }

  .gaminioNuotrauka:first-child {
    margin-left: 0px;
  }

  .download {
    width: 242px;
  }

  .bigImage {
    width: 858px;
    height: auto;
    margin-left: 88px;
  }

  .bigImageArrowDiv > .arrowLeftImg {
    top: 30px;
  }

  .bigImageArrowDiv > .arrowRightImg {
    top: 30px;
    left: 1002px;
  }

  .nuotraukos {
    margin-left: 0;
  }

  .nuotraukos.bigKarusele {
    margin-left: 86px;
  }

  .ikonos.bigImageIkonos .arrowLeftImg {
    top: 10px;
    left: 710px;
  }

  .ikonos.bigImageIkonos .arrowRightImg {
    top: 10px;
    left: 765px;
  }

  .infoText {
    margin-left: 105px;
  }

  .pvz .excl {
    top: 308px;
    right: 727px;
  }

  .pvzTop h5 {
    position: relative;
    right: 107px;
  }

  .gaminioNuotrauka {
    width: 200px;
    height: 200px;
  }

  .nuotraukos.bigKarusele .gaminioNuotrauka {
    margin-left: 20px;
  }

  .nuotraukos.bigKarusele .gaminioNuotrauka:first-child {
    margin-left: 0px;
  }

  .zinute .validationError {
    top: 141px;
  }
  
}

@media only screen and (max-width: 1100px) {
  .productCard {
    height: 382px;
    width: 382px;
  }

  .pavadinimas > h3 {
    font-size: 44px;
  }

  .nuotraukos.bigKarusele .gaminioNuotrauka {
    margin-left: 12px;
  }

  .headerSearchDiv {
    min-width: 290px;
  }

  .pvz {
    left: 0px;
    width: 780px;
  }

  .nuotraukos {
    position: relative;
    right: 106px;
  }

  .papildomaInfo {
    left: 0;
  }

  .papildomaInfo .download {
    width: 260px;
  }

  .infoText {
    padding-right: 20px;
  }

  .productCard > .categories {
    top: 240px;
  }

  .cardImg {
    margin-left: -10px;
  }

  .cardArrow {
    top: 328px;
    left: 302px;
  }

  .productList {
    width: 780px;
  }

  .mechaniniaiKomponentai {
    width: 780px;
    height: 333px;
  }

  .komponentuImgDiv {
    /* margin-left: -428px; */
    display: none;
  }

  .komponentuDiv {
    padding: 83px 0 83px 72px;
  }

  .mainPageProducts {
    width: 780px;
  }

  .headerDiv {
    width: 780px;
  }

  .operacijos {
    width: 780px;
    height: 737px;
  }

  .operacijos > img {
    width: 780px;
    height: 737px;
  }

  .susisiekimas {
    width: 780px;
    height: 700px;
  }

  .kont1, .kont2 {
    padding-right: 20px;
  }

  .kont2, .kont3 {
    padding-left: 20px;
  }

  .kont > div {
    font-size: 19px;
  }

  .kont1 {
    width: 290px;
  }

  .kont2 {
    width: 160px;
  }

  .kont3 {
    width: 246px;
  }

  .textInputDiv {
    margin-left: 0;
    width: 474px;
  }

  .zinute .inputComment {
    margin-left: 0;
  }

  form button {
    margin-left: 306px;
    margin-top: 10px;
  }

  .locationImage {
    width: 640px;
    height: 440px;
    margin-left: 150px;
  }

  .locationImageMap {
    width: 640px;
    height: 440px;
    margin-left: 150px;
  }

  .footerDiv > div {
    width: 780px;
  }

  .footerDiv {
    height: 440px;
  }

  .imonesInfo {
    height: 400px;
    margin-top: 60px;
  }

  .location {
    height: 510px;
    width: 800px;
  }

  .apieMus, .esParam {
    width: 730px;
    padding-left: 50px;
  }

  .apieMusTop img, .esParamTop img {
    display: none;
  }

  .apieMusTop > div > div, .esParamTop > div > div {
    width: 660px;
  }

  .apieMusBot > div > div, .esParamBot > div > div {
    width: 660px;
  }

  .kolektyvas {
    width: 780px;
    height: 550px;
  }

  .asmuo {
    width: 420px;
  }

  .kolektyvas > div:nth-child(2) {
    border: none;
    padding-bottom: 0px;
  }

  .kolektyvas > div {
    margin-top: 0px;
    width: 600px;
  }

  .productPage {
    width: 780px;
  }

  .lentele img {
    display: none;
  }

  .gaminiai, .tech {
    width: 780px;
  }

  .gaminiai > div:nth-child(2) {
    left: 76px;
    width: 600px;
  }

  .tech > div:nth-child(2) {
    left: 76px;
    width: 600px;
  }

  .gaminioNuotrauka {
    margin-left: 12px;
    width: 171px;
    height: 171px;
  }

  .gaminioNuotrauka:first-child {
    margin-left: 0px;
  }

  .infoText {
    margin-left: 0;
  }

  .papildomaInfo .download {
    margin-left: 20px;
  }

  .pvz .excl {
    top: 310px;
    right: 205px;
  }

  .nuotraukos {
    margin-top: 10px;
  }

  .nuotraukos.bigKarusele {
    width: 720px;
    margin-left: 30px;
    right: 0;
  }

  .ikonos .arrowLeftImg {
    top: 285px;
    left: 472px;
  }

  .ikonos .arrowRightImg {
    top: 285px;
    left: 522px;
  }

  .bigImage {
    width: 720px;
    margin-left: 30px;
  }

  .bigImageArrowDiv > .arrowLeftImg {
    top: 77px;
    left: 0px;
  }

  .bigImageArrowDiv > .arrowRightImg {
    top: 77px;
    left: 750px;
  }

  .ikonos.bigImageIkonos .arrowLeftImg {
    top: 10px;
    left: 535px;
  }

  .ikonos.bigImageIkonos .arrowRightImg {
    top: 10px;
    left: 585px;
  }

  .iksas {
    height: 23px;
    position: relative;
    right: 27px;
    top: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .productList {
    width: 380px;
    display: none;
    column-gap: 0;
  }

  .locationImageMap {
    display: none;
  }

  .nuotraukos.bigKarusele {
    margin-left: 0;
  }

  .nuotraukos.bigKarusele .gaminioNuotrauka {
    margin-left: 0px;
  }

  .dropdownMobile {
    display: inherit;
    width: 380px;
    height: 100%;
    margin-top: 15px;
  }

  .dropdownMobileBackground {
    position: absolute;
    left: 0;
    top: 183px;
    width: 100%;
    height: 100%;
    background-color: #EDEDED;
    z-index: 900;
  }

  .dropdownDesktop {
    display: none;
  }

  .pavadinimas > h3 {
    font-size: 26px;
  }

  .karusele.karuseleMobile {
    display: inherit;
    padding-top: 45px;
  }

  .gaminiai > div:nth-child(2) {
    width: 380px;
  }

  .bigImage {
    width: 380px;
    margin-left: 0;
    margin-top: 0;
  }

  .bigImageArrowDiv > .arrowLeftImg {
    left: -20px;
    top: 180px;
  }

  .bigImageArrowDiv > .arrowRightImg {
    left: 370px;
    top: 180px;
  }

  .karusele img {
    right: 0;
    top: -10px;
  }

  .productListMobile {
    display: block;
  }

  .productCard {
    width: 380px;
    height: 250px;
    margin-bottom: 16px;
  }

  .susisiekimasMobile, .katalogasMobile, .mobileSearchDiv, .headerDivMobile, .galimybes .buttonDiv {
    display: inherit;
  }

  .dropdownItem {
    width: 280px;
  }

  .dropdownMobile .iksas {
    position: absolute;
    margin-top: 0;
    height: 30px;
    width: 30px;
    top: 24px;
    right: 0px;
  }

  .dropdownMenu ul {
    padding-top: 0px;
    margin-top: 10px;
    padding-left: 0;
  }

  .katalogasMobile .excl {
    position: inherit;
    margin-bottom: 10px;
  }

  .katalogasMobile .infoText {
    margin-left: 0;
    width: 380px;
  }

  .katalogasMobile .download {
    width: 360px;
    margin-left: 0px;
    margin-top: 40px;
  }

  .productList .download {
    width: 360px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .katalogasMobile {
    padding-top: 40px;
    padding-bottom: 50px;
    border-top: 1px solid #001A3D;
    border-bottom: 1px solid #001A3D;
  }

  .productCard h3 {
    margin-top: 0;
    padding-top: 30px;
  }

  .productCard > .categories {
    top: 100px;
  }

  .cardImg {
    display: none;
  }

  .cardArrow {
    top: 196px;
    left: 297px;
  }

  .imonesInfo {
    width: 380px;
  }

  .mechaniniaiKomponentai {
    background-color: inherit;
    width: 380px;
    height: 180px;
  }

  .mechaniniaiKomponentai div {
    color: #001A3D;
  }

  .komponentuDiv {
    width: 380px;
    padding: 0;
  }

  .komponentuImgDiv {
    display: none;
  }

  .paskirtis {
    font-size: 16px;
  }

  .paskirtis > div {
    border-left: 1px solid #001A3D;
  }

  .paskirtis > div:nth-child(2) {
    padding-right: 0;
  }

  .komponentai {
    font-size: 30px;
  }

  .headerSearchDiv {
    display: none;
  }

  .productCard > h3 {
    width: 300px;
  }

  .languageDiv {
    position: relative;
    top: 125px;
  }

  .galimybesPlaciau {
    display: none;
  }

  .kont > div {
    float: inherit;
    padding: 0;
    margin: 0;
    height: 50px;
    border: none;
  }

  .galimybesPlaciau.galimybesOpen{
    display: inherit;
  }

  .galimybes .buttonDiv {
    display: inherit;
    font-weight: 600;
  }

  .gaminiai > div, .tech > div {
    float: inherit;
  }

  .gaminiai > h5, .tech > h5 {
    float: inherit;
    border-bottom: 1px solid #001A3D;
    padding-bottom: 10px;
  }

  .tech h5 {
    width: 175px;
  }

  .gaminiai > div:nth-child(2) {
    left: 0;
  }

  .tech > div:nth-child(2){
    left: 0;
  }

  .galimybes {
    padding-bottom: 40px;
  }

  .mainPageProducts {
    width: 380px;
  }

  .headerDiv {
    width: 380px;
  }

  .galimybes > h3, .galimybes > p {
    margin-left: 0;
  }

  .operacijos{
    display: none;
  }

  .operacijos > h3 {
    display: none;
  }

  .mobileOperacijosH {
    display: none;
  }

  .susisiekimas {
    display: none;
  }

  .nuotraukos {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
    row-gap: 16px;
    width: 380px;
    margin-bottom: 0px;
    margin-left: 0;
  }

  .nuotraukos.bigKarusele {
    width: 380px;
  }

  .lentele {
    padding-bottom: 0;
  }

  .gaminioNuotrauka {
    margin-left: 0;
    width: 182px;
    height: 182px;
  }

  .papildomaInfo {
    display: none;
  }

  .productPage .excl {
    display: none;
  }

  .productPage .katalogasMobile {
    border-bottom: none;
  }

  .footerDiv {
    height: 475px;
  }

  .location {
    display: none;
  }

  .imonesInfo {
    height: 380px;
    margin-top: 50px;
  }

  .footerDiv > div {
    width: 380px;
  }

  .apieMus, .esParam {
    width: 340px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
  }

  .apieMusTop > div > div {
    margin-right: 0px;
    width: 340px;
  }

  .apieMus > .apieMusTop img {
    display: none;
  }

  .apieMusBot > div > div {
    width: 340px;
  }

  .kolektyvas {
    width: 380px;
    height: 640px;
  }

  .asmuo {
    float: inherit;
  }

  .asmuo > img {
    margin-right: 40px;
  }

  .kolektyvas > div {
    margin-left: 0;
    margin-top: 0;
    width: 380px;
  }

  .kolektyvas > div:nth-child(2){
    border: none;
    padding-bottom: 0;
  }

  .esParamTop > div > div {
    width: 340px;
  }

  .esParam img {
    display: none;
  }

  .esParam {
    padding-bottom: 40px;
    margin-bottom: 16px;
  }

  .productPage {
    width: 380px;
  }

  .karusele > div {
    display: none;
  }

  .karusele.karuseleMobile > div {
    display: inherit;
    height: 30px;
    z-index: 100;
  }

  .karusele.karuseleMobile .borderDiv {
    display: none;
  }

  .karusele.karuseleMobile .arrowLeftImg, .karusele.karuseleMobile .arrowRightImg {
    height: 30px;
    bottom: 20px;
    margin-right: -5px;
  }

  .gaminiai, .tech {
    width: 380px;
    border: none;
  }

  .gaminiai {
    margin-bottom: 40px;
  }

  .tech > div:nth-child(2){
    width: 380px;
  }

  .lentele {
    display: none;
  }

  .gaminioLentele {
    display: none;
  }

  .nuotrauka5 {
    display: none;
  }

  .ikonos {
    margin-left: 0;
  }

  .ikonos .arrowLeftImg{
    top: 500px;
    left: 213px;
  }

  .ikonos .arrowRightImg{
    top: 500px;
    left: 258px;
  }

  .ikonos.bigImageIkonos .arrowLeftImg {
    top: 20px;
    left: 300px;
  }

  .ikonos.bigImageIkonos .arrowRightImg {
    top: 20px;
    left: 350px;
  }

  .galimybes > p {
    width: 380px;
  }

  .download {
    width: 242px;
  }

  .headerButtonsDiv {
    display: none;
  }

  .pvz {
    width: 380px;
    padding-bottom: 110px;
  }
}

@media only screen and (max-width: 800px) {
  .pvzTop{
    width: 0px;
  }

  .katalogasMobile .infoText {
    padding-right: 0;
  }
}

@media only screen and (max-width: 450px) {
  .bigImageArrowDiv > .arrowLeftImg {
    left: 0px;
    top: 30px;
  }

  .bigImageArrowDiv > .arrowRightImg {
    left: 50px;
    top: 30px;
  }
}

@media only screen and (max-width: 410px) {
  .productList {
    width: 330px;
  }

  .pavadinimas > h3 {
    font-size: 24px;
  }

  .tech h5 {
    width: 200px;
  }

  .karusele > div:first-child {
    padding-right: 5px;
  }

  .pavadinimas{
    height: 80px;
  }

  .asmuo > img {
    display: none;
  }

  .dropdownMobile {
    width: 330px;
  }

  .paskirtis > div:nth-child(2) {
    padding-right: 10px;
  }

  .gaminiai > div:nth-child(2) {
    width: 330px;
  }

  .bigImage {
    width: 330px;
  }

  .bigImageArrowDiv > .arrowLeftImg {
    top: 60px;
  }

  .bigImageArrowDiv > .arrowRightImg {
    top: 60px;
  }

  .karusele img {
    top: -17px;
  }

  .productCard {
    width: 330px;
    margin-bottom: 16px;
  }

  .dropdownItem {
    width: 230px;
  }

  .katalogasMobile .infoText {
    width: 330px;
  }

  .katalogasMobile .download {
    width: 310px;
  }

  .productList .download {
    width: 310px;
  }

  .cardArrow {
    top: 196px;
    left: 262px;
  }

  .imonesInfo {
    width: 330px;
  }

  .mechaniniaiKomponentai {
    width: 330px;
  }

  .komponentuDiv {
    width: 330px;
  }

  .productCard > h3 {
    font-size: 26px;
    margin-left: 25px;
  }

  .productCard > .categories {
    margin-left: 25px;
  }

  .mainPageProducts {
    width: 330px;
  }

  .headerDiv {
    width: 330px;
  }

  .nuotraukos {
    column-gap: 10px;
    row-gap: 10px;
    width: 330px;
  }

  .nuotraukos.bigKarusele {
    width: 330px;
  }

  .gaminioNuotrauka {
    margin-left: 0;
    width: 160px;
    height: 160px;
  }

  .imonesInfo {
    height: 330px;
    margin-top: 50px;
  }

  .footerDiv > div {
    width: 330px;
  }

  .apieMus, .esParam {
    width: 290px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
  }

  .apieMusTop > div > div {
    margin-right: 0px;
    width: 290px;
  }

  .apieMusBot > div > div {
    width: 290px;
  }

  .kolektyvas {
    width: 330px;
  }

  .kolektyvas > div {
    width: 330px;
  }

  .esParamTop > div > div {
    width: 290px;
  }

  .productPage {
    width: 330px;
  }

  .karusele.karuseleMobile {
    padding-top: 45px;
  }

  .gaminiai, .tech {
    width: 330px;
  }

  .tech > div:nth-child(2){
    width: 310px;
  }

   .ikonos .arrowLeftImg{
    top: 450px;
    left: 163px;
  }

  .ikonos .arrowRightImg{
    top: 450px;
    left: 208px;
  }

  .ikonos.bigImageIkonos .arrowLeftImg {
    left: 250px;
  }

  .ikonos.bigImageIkonos .arrowRightImg {
    left: 300px;
  }

  .galimybes > p {
    width: 330px;
  }

  .pvz {
    width: 330px;
    padding-bottom: 110px;
  }
}